@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .placeholder-text-14::placeholder {
    font-size: 14px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background: #F6F7FA;
  padding-top:72px ;
}



.no-global-styles div {
  /* background-color: #0000F0; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.react-datepicker__header {
  background-color: #fff !important;
}

.react-datepicker__header{
  border:none !important;
}
.react-datepicker__year-select{
  display: none;
}

.react-datepicker__month-select{
  display: none;
}

.react-datepicker__day--selected {
  background-color: #3B97FF; 
  color: #fff;
}

.react-datepicker__day--selected:hover {
  background-color: #3B97FF; 
  color: #fff;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text{
  font-size: 12px;
  font-weight: 700;
}

.react-datepicker__time-box > ul{
  list-style: none !important;
  padding-left: 0px !important;

}

.react-datepicker__day-name{
  color: #6B7280;
  font-size: 12px;
  font-weight: 600;
}


.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: transparent;
}

.react-datepicker{
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D1D5DB);
  background: var(--white, #FFF);
  
  /* shadow */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
  overflow:hidden
}

/* react-quill css start  */
.ql-toolbar.ql-snow {
  border-color: #c3c1df !important;
  border-left: 0px !important;
  border-right: 0px !important;
  background-color: #fafafe !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.ql-container.ql-snow {
  border: none !important;
  padding-bottom: 58px;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.ql-editor a img {
  display: inline-block;
}

.css-wmy1p7-ReactDropdownSelect:focus,
.css-wmy1p7-ReactDropdownSelect:focus-within {
  box-shadow: none !important;
}

/* .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0px !important;
} */

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  bottom: 160%;
  left: -570%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  left: 50%;
  z-index: 1;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  bottom: 160%;
  right: -52%;
  margin-left: -60px;
}

.tooltip2 .tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 2%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
}

.tooltip2 .tooltiptext2::before {
  content: "";
  position: absolute;
  z-index: 1;
  right: 2%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.arrow {
  background-image: url(assets/svg/arrow.svg);
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

@media only screen and (max-width: 1279px) {
  .tooltip .tooltiptext {
    left: auto;
    right: -52%;
  }

  .tooltip .tooltiptext::after {
    right: 2%;
    left: auto;
  }

  .tooltip .tooltiptext::before {
    right: 2%;
    left: auto;
  }
}

@media only screen and (max-width: 320px) {
  .tooltip .tooltiptext {
    max-width: 270px;
  }

  .tooltip2 .tooltiptext2 {
    max-width: 270px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  --ckeditor5-preview-sidebar-width: 270px;
  /* width: fit-content; */
  margin-left: auto;
  margin-right: auto;
}

.main-container .presence {
  margin-bottom: 8px;
}

.react-datepicker-wrapper{
  width: 100%;
}

.ck-content {
  line-height: 1.6;
  word-break: break-word;
  height: 490px;
}

.editor-container__editor-wrapper {
  display: flex;
  width: fit-content;
}

.editor-container_include-outline
  .editor-container__editor
  .ck.ck-editor__editable {
  margin-left: 0;
}

.editor-container_include-annotations
  .editor-container__editor
  .ck.ck-editor__editable {
  margin-right: 0;
}

/* .editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
} */

.editor-container__sidebar {
  min-width: var(--ckeditor5-preview-sidebar-width);
  max-width: var(--ckeditor5-preview-sidebar-width);
  margin-top: 28px;
  margin-left: 10px;
  margin-right: 10px;
}

.revision-history {
  display: none;
}

.revision-history__wrapper {
  display: flex;
}

.revision-history__wrapper .ck.ck-editor {
  margin: 0;
  width: 795px;
}

.revision-history__wrapper .revision-history__sidebar {
  border: 1px solid var(--ck-color-base-border);
  border-left: 0;
  width: var(--ckeditor5-preview-sidebar-width);
  min-height: 100%;
}

.revision-history__wrapper
  .revision-history__sidebar
  .ck-revision-history-sidebar {
  height: 100%;
}

.header-element {
  padding-right: 14px;
}

.no-global-styles .header-element {
  padding-right: 0px;
}

.header-element:last-child {
  padding-right: 20px;
}

.no-global-styles .header-element:last-child {
  padding-right: 0px;
}

.header-element:first-child {
  padding-right: 20px;
}

.no-global-styles .header-element:first-child {
  padding-right: 0px;
}

/* .ck-content li {
  list-style-position: inside;
} */

p::marker {
  content: none;
}

.ck-editor__editable .ck-list-bogus-paragraph {
  display: inline-block !important;
}

.ck .ck-link_selected {
  background: var(--ck-color-link-selected-background);
  color: #0000f0;
  cursor: pointer;
}

a {
  color: #0000f0;
  text-decoration: inherit;
  cursor: pointer;
}

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}

ol {
  list-style: decimal;
  padding-left: 30px;
}

.no-global-styles ol {
  padding-left: 20px;
}

ol ol {
  list-style: lower-latin;
  padding-left: 10px;
}

.no-global-styles ol ol {
  padding-left: 15px;
}

ol ol ol {
  list-style: lower-roman;
  padding-left: 20px;
}

.no-global-styles ol ol ol {
  padding-left: 20px;
}

ol ol ol ol {
  list-style: upper-latin;
}

.no-global-styles ol ol ol ol {
  padding-left: 20px;
}

ol ol ol ol ol {
  list-style: upper-roman;
}

ul {
  list-style: revert !important;
  padding-left: 30px !important;
}

.no-global-styles ul {
  padding-left: 10px !important;
}

li {
  margin-block: 10px;
}

.no-global-styles ul {
  margin-block: 0px;
}

.ck.ck-editor__main p {
  font-size: 1em;
  line-height: 1.6em;
  padding-top: 0.2em;
}

.ck.ck-editor__main h1 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 2.3em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h2 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.84em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h3 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.48em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h4 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.22em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h5 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.06em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h6 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck-content code {
  background-color: transparent;
}

.ql-snow .ql-editor code {
  padding: 0;
}

.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: transparent;
  border-radius: 0px;
}

.marker-red {
  background-color: rgb(231, 19, 19);
}

/* UTILITIES */
.tableShadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.heading-bold {
  @apply text-[18px] font-semibold;
}

.heading-regular-grey {
  @apply text-[#696868] dark:text-white text-base font-normal;
}

.text-regular {
  @apply text-[#393C51]  dark:text-white  font-normal text-base;
}

.text-medium {
  @apply text-[#393C51]  dark:text-white  font-medium;
}

.text-regular-bold {
  @apply text-secondary dark:text-white font-bold text-base;
}

.table-container {
  /* @apply border border-[#DCDBEE] rounded-radius-sm overflow-hidden; */
}

/* TYPOGRAPHY */
/* TEXT XS */
.text-xs-thin {
  @apply text-xs font-thin leading-normal;
}

.text-xs-extralight {
  @apply text-xs font-extralight leading-normal;
}

.text-xs-light {
  @apply text-xs font-light leading-normal;
}

.text-xs-normal {
  @apply text-xs font-normal leading-normal;
}

.text-xs-medium {
  @apply text-xs font-medium leading-normal;
}

.text-xs-semibold {
  @apply text-xs font-semibold leading-normal;
}

.text-xs-bold {
  @apply text-xs font-bold leading-normal;
}

.text-xs-extrabold {
  @apply text-xs font-extrabold leading-normal;
}

.text-xs-black {
  @apply text-xs font-black leading-normal;
}

/* TEXT SM */
.text-sm-thin {
  @apply text-sm font-thin leading-normal;
}

.text-sm-extralight {
  @apply text-sm font-extralight leading-normal;
}

.text-sm-light {
  @apply text-sm font-light leading-normal;
}

.text-sm-normal {
  @apply text-sm font-normal leading-normal;
}

.text-sm-medium {
  @apply text-sm font-medium leading-normal;
}

.text-sm-medium {
  @apply text-sm font-medium leading-normal;
}

.text-sm-semibold {
  @apply text-sm font-semibold leading-normal;
}

.text-sm-bold {
  @apply text-sm font-bold leading-normal;
}

.text-sm-extrabold {
  @apply text-sm font-extrabold leading-normal;
}

.text-sm-black {
  @apply text-sm font-black leading-normal;
}

/* TEXT BASE */
.text-base-thin {
  @apply text-base font-thin leading-normal;
}

.text-base-extralight {
  @apply text-base font-extralight leading-normal;
}

.text-base-light {
  @apply text-base font-light leading-normal;
}

.text-base-normal {
  @apply text-base font-normal leading-normal;
}

.text-base-medium {
  @apply text-base font-medium leading-normal;
}

.text-base-semibold {
  @apply text-base font-semibold leading-normal;
}

.text-base-bold {
  @apply text-base font-bold leading-normal;
}

.text-base-extrabold {
  @apply text-base font-extrabold leading-normal;
}

.text-base-black {
  @apply text-base font-black leading-normal;
}

/* TEXT LG */
.text-lg-thin {
  @apply text-lg font-thin leading-normal;
}

.text-lg-extralight {
  @apply text-lg font-extralight leading-normal;
}

.text-lg-light {
  @apply text-lg font-light leading-normal;
}

.text-lg-normal {
  @apply text-lg font-normal leading-normal;
}

.text-lg-medium {
  @apply text-lg font-medium leading-normal;
}

.text-lg-semibold {
  @apply text-lg font-semibold leading-normal;
}

.text-lg-bold {
  @apply text-lg font-bold leading-normal;
}

.text-lg-extrabold {
  @apply text-lg font-extrabold leading-normal;
}

.text-lg-black {
  @apply text-lg font-black leading-normal;
}

/* TEXT XL */
.text-xl-thin {
  @apply text-xl font-thin leading-normal;
}

.text-xl-extralight {
  @apply text-xl font-extralight leading-normal;
}

.text-xl-light {
  @apply text-xl font-light leading-normal;
}

.text-xl-normal {
  @apply text-xl font-normal leading-normal;
}

.text-xl-medium {
  @apply text-xl font-medium leading-normal;
}

.text-xl-semibold {
  @apply text-xl font-semibold leading-normal;
}

.text-xl-bold {
  @apply text-xl font-bold leading-normal;
}

.text-xl-extrabold {
  @apply text-xl font-extrabold leading-normal;
}

.text-xl-black {
  @apply text-xl font-black leading-normal;
}

/* TEXT 2XL */
.text-2xl-thin {
  @apply text-2xl font-thin leading-normal;
}

.text-2xl-extralight {
  @apply text-2xl font-extralight leading-normal;
}

.text-2xl-light {
  @apply text-2xl font-light leading-normal;
}

.text-2xl-normal {
  @apply text-2xl font-normal leading-normal;
}

.text-2xl-medium {
  @apply text-2xl font-medium leading-normal;
}

.text-2xl-semibold {
  @apply text-2xl font-semibold leading-normal;
}

.text-2xl-bold {
  @apply text-2xl font-bold leading-normal;
}

.text-2xl-extrabold {
  @apply text-2xl font-extrabold leading-normal;
}

.text-2xl-black {
  @apply text-2xl font-black leading-normal;
}

/* TEXT 3XL */

.text-3xl-thin {
  @apply text-3xl font-thin leading-normal;
}

.text-3xl-extralight {
  @apply text-3xl font-extralight leading-normal;
}

.text-3xl-light {
  @apply text-3xl font-light leading-normal;
}

.text-3xl-normal {
  @apply text-3xl font-normal leading-normal;
}

.text-3xl-medium {
  @apply text-3xl font-medium leading-normal;
}

.text-3xl-semibold {
  @apply text-3xl font-semibold leading-normal;
}

.text-3xl-bold {
  @apply text-3xl font-bold leading-normal;
}

.text-3xl-extrabold {
  @apply text-3xl font-extrabold leading-normal;
}

.text-3xl-black {
  @apply text-3xl font-black leading-normal;
}

/* TEXT 4 */

.text-4xl-thin {
  @apply text-4xl font-thin leading-normal;
}

.text-4xl-extralight {
  @apply text-4xl font-extralight leading-normal;
}

.text-4xl-light {
  @apply text-4xl font-light leading-normal;
}

.text-4xl-normal {
  @apply text-4xl font-normal leading-normal;
}

.text-4xl-medium {
  @apply text-4xl font-medium leading-normal;
}

.text-4xl-semibold {
  @apply text-4xl font-semibold leading-normal;
}

.text-4xl-bold {
  @apply text-4xl font-bold leading-normal;
}

.text-4xl-extrabold {
  @apply text-4xl font-extrabold leading-normal;
}

.text-4xl-black {
  @apply text-4xl font-black leading-normal;
}

/* TEXT 5-XL */
.text-5xl-thin {
  @apply text-5xl font-thin leading-normal;
}

.text-5xl-extralight {
  @apply text-5xl font-extralight leading-normal;
}

.text-5xl-light {
  @apply text-5xl font-light leading-normal;
}

.text-5xl-normal {
  @apply text-5xl font-normal leading-normal;
}

.text-5xl-medium {
  @apply text-5xl font-medium leading-normal;
}

.text-5xl-semibold {
  @apply text-5xl font-semibold leading-normal;
}

.text-5xl-bold {
  @apply text-5xl font-bold leading-normal;
}

.text-5xl-extrabold {
  @apply text-5xl font-extrabold leading-normal;
}

.text-5xl-black {
  @apply text-5xl font-black leading-normal;
}

/* TEXT 6-XL */

.text-6xl-thin {
  @apply text-6xl font-thin leading-normal;
}

.text-6xl-extralight {
  @apply text-6xl font-extralight leading-normal;
}

.text-6xl-light {
  @apply text-6xl font-light leading-normal;
}

.text-6xl-normal {
  @apply text-6xl font-normal leading-normal;
}

.text-6xl-medium {
  @apply text-6xl font-medium leading-normal;
}

.text-6xl-semibold {
  @apply text-6xl font-semibold leading-normal;
}

.text-6xl-bold {
  @apply text-6xl font-bold leading-normal;
}

.text-6xl-extrabold {
  @apply text-6xl font-extrabold leading-normal;
}

.text-6xl-black {
  @apply text-6xl font-black leading-normal;
}

/* TEXT 7-XL */

.text-7xl-thin {
  @apply text-7xl font-thin leading-normal;
}

.text-7xl-extralight {
  @apply text-7xl font-extralight leading-normal;
}

.text-7xl-light {
  @apply text-7xl font-light leading-normal;
}

.text-7xl-normal {
  @apply text-7xl font-normal leading-normal;
}

.text-7xl-medium {
  @apply text-7xl font-medium leading-normal;
}

.text-7xl-semibold {
  @apply text-7xl font-semibold leading-normal;
}

.text-7xl-bold {
  @apply text-7xl font-bold leading-normal;
}

.text-7xl-extrabold {
  @apply text-7xl font-extrabold leading-normal;
}

.text-7xl-black {
  @apply text-7xl font-black leading-normal;
}
/* TEXT 8-XL */
.text-8xl-thin {
  @apply text-8xl font-thin leading-normal;
}

.text-8xl-extralight {
  @apply text-8xl font-extralight leading-normal;
}

.text-8xl-light {
  @apply text-8xl font-light leading-normal;
}

.text-8xl-normal {
  @apply text-8xl font-normal leading-normal;
}

.text-8xl-medium {
  @apply text-6xl font-medium leading-normal;
}

.text-8xl-semibold {
  @apply text-8xl font-semibold leading-normal;
}

.text-8xl-bold {
  @apply text-8xl font-bold leading-normal;
}

.text-8xl-extrabold {
  @apply text-8xl font-extrabold leading-normal;
}

.text-8xl-black {
  @apply text-8xl font-black leading-normal;
}

/* TEXT 9-XL */

.text-9xl-thin {
  @apply text-9xl font-thin leading-normal;
}

.text-9xl-extralight {
  @apply text-9xl font-extralight leading-normal;
}

.text-9xl-light {
  @apply text-9xl font-light leading-normal;
}

.text-9xl-normal {
  @apply text-9xl font-normal leading-normal;
}

.text-9xl-medium {
  @apply text-9xl font-medium leading-normal;
}

.text-9xl-semibold {
  @apply text-9xl font-semibold leading-normal;
}

.text-9xl-bold {
  @apply text-9xl font-bold leading-normal;
}

.text-9xl-extrabold {
  @apply text-9xl font-extrabold leading-normal;
}

.text-9xl-black {
  @apply text-9xl font-black leading-normal;
}




